
import Slider from './Home/Slider'
import IntroZSVP from './Home/IntroZSVP'
import Tenders from './Home/Tenders'
import Reports from './Home/Reports'
import Gallery from './Home/Gallery/ZSVP_Gallery'
import Partners from './About/Partners'
import { Helmet } from 'react-helmet'

import './home.css'

function Home() {
    return (
        <main className='homeMain'>
            <Helmet>
                <meta charset='utf-8' />
                <title>HOME - ZSVP ORG</title>
            </Helmet>
            <Slider />
            <IntroZSVP />
            <Tenders />
            <Reports />
            <Partners />
            <Gallery />
        </main>
    )
}

export default Home;