import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'

import { Link, useParams } from 'react-router-dom'

function NewsPage() {
    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [news, setNews] = useState([])

    useEffect(
        () => {
            axios.get(`/wp-json/wp/v2/news/${id}`)
                .then(
                    resp => {
                        setNews(resp.data)
                        setLoading(true)
                    }
                )
                .catch(err => console.log(err))
        }, [id]
    )
    return (
        <main className='news-page-main'>
            {
                isLoading ?
                    <section className='news-page-section'>
                        <Link to='/news' className='back'> ⬅  Back</Link>
                        <h4 dangerouslySetInnerHTML={{ __html: news.title.rendered }}></h4>
                        <p className='news-date'>{news.date}</p>
                        <p dangerouslySetInnerHTML={{ __html: news.content.rendered }}></p>

                    </section>
                    :
                    <Fragment>
                        <div style={{ 'margin-top': '3rem' }}>
                            <p>Loading...</p>
                        </div>
                    </Fragment>
            }
        </main>
    )

}

export default NewsPage;