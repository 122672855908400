export const photos = [
    {
      src: "/images/gallery/3.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/1.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/2.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/4.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/5.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/6.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/7.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/8.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/images/gallery/9.jpg",
      width: 4,
      height: 3
    }
  ];
  