import React, { Fragment } from 'react'
import './Reports.css'
import axios from 'axios'
import {AiFillBook} from 'react-icons/ai'

class Reports extends React.Component {
    constructor() {
        super()
        this.state = {
            reports: [],
            isLoading: false
        }
    }
    componentDidMount() {
        axios.get('/wp-json/wp/v2/reports')
            .then(
                resp => {
                    this.setState(
                        {
                            reports: resp.data,
                            isLoading: true
                        }
                    )
                }
            )
            .catch();
    }
    render() {
        let { reports, isLoading } = this.state;
        return (
            <section className='reports'>
                <h3>Reports &amp; Projects</h3>
                {
                    isLoading ?
                        <Fragment>
                            {
                                reports.length !== 0 ?
                                    <ul>
                                        {
                                            reports.map(report => {
                                                return (
                                                    <li key={report.id}>
                                                        <AiFillBook />
                                                        <div>
                                                            <strong>{report.title.rendered}</strong>
                                                            <a href={replaceTageP(report.content.rendered)}
                                                                target='_blank'
                                                                rel='noopener noreferrer'>Read Report</a>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    :
                                    <div style={{textAlign:'center'}}>No Reports Yet!</div>
                            }
                        </Fragment>
                        :
                        <div>
                            Loading...
                        </div>
                }
            </section>
        )
    }
}

function replaceTageP(parm) {
    parm = parm.replace('<p>', '');
    parm = parm.replace('</p>', '');
    return parm
}

export default Reports;