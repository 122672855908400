import React from 'react'
import './Footer.css'
import ListGroup from 'react-bootstrap/ListGroup'

import { IoLogoTwitter } from 'react-icons/io'
import { CgFacebook } from 'react-icons/cg'
import { RiLinkedinBoxFill } from 'react-icons/ri'
import { MdEmail } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'


import { Link } from 'react-router-dom'

class Footer extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false
        }
    }
    render() {
        return (
            <footer>
                <section className='footerSection'>

                    <div className='pages'>
                        <ListGroup variant="flush">
                            <ListGroup.Item> <Link to='/'>Home</Link></ListGroup.Item>
                            <ListGroup.Item> <Link to='/about'>About</Link></ListGroup.Item>
                            <ListGroup.Item> <Link to='/news'>News</Link></ListGroup.Item>
                            <ListGroup.Item> <Link to='/jobs'>Jobs</Link></ListGroup.Item>
                            <ListGroup.Item>
                                <a href='https://gator3288.hostgator.com:2096/webmaillogout.cgi'
                                    target='_blank' rel='noopener noreferrer'>
                                    Login
                                </a>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>

                    <div className='workdays'>
                        <h5>Work days &amp; work times</h5>
                        <ListGroup variant="flush">
                            <ListGroup.Item><strong>Sunday: </strong> 08:00AM - 03:30PM</ListGroup.Item>
                            <ListGroup.Item><strong>Monday: </strong> 08:00AM - 03:30PM</ListGroup.Item>
                            <ListGroup.Item><strong>Tuesday: </strong> 08:00AM - 03:30PM</ListGroup.Item>
                            <ListGroup.Item><strong>Wednesday: </strong> 08:00AM - 03:30PM</ListGroup.Item>
                            <ListGroup.Item><strong>Thursday: </strong> 08:00AM - 03:00PM</ListGroup.Item>
                        </ListGroup>
                    </div>


                    <div className='socialNetworks'>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <a href='https://twitter.com/dr_zsvp'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <IoLogoTwitter />
                                    Twitter
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href='https://www.facebook.com/ZSVP-ORG-621286201330657'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <CgFacebook />
                                    Facebook
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href='https://www.linkedin.com/in/dr-abid-hassan-a97bbb16/'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <RiLinkedinBoxFill />
                                    Linkedin
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item><a href="mailto:information@zsvp.org" style={{color: 'white'}}> <MdEmail /> information@zsvp.org</a></ListGroup.Item>
                            <ListGroup.Item><FaPhoneAlt /> +964 751 223 6180</ListGroup.Item>
                        </ListGroup>
                    </div>
                </section>
                <section className='copyright'>
                    <div>Duhok, Iraq</div>
                    <div>&copy;ZSVP ORG 2021</div>
                </section>
                <section className='developer'>
                    <a href='https://dev-nechir.netlify.app/' target='_blank' rel='noopener noreferrer'>Developer:<strong>Nechir</strong></a>
                </section>
            </footer>
        )
    }
}

export default Footer;