import React from 'react'
import {Link } from 'react-router-dom'
import './PageNotFound.css'
function PageNotFound(){

    return (
        <section className='page-not-found-section'>
            <div>
                <Link to='/'>⬅  Back to Home Page</Link>
                <p className='page-not-found-p'>Sorry, the page can not be found!</p>
            </div>
        </section>
    )
}

export default PageNotFound;