import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import './Jobs.css'

import { GoLocation } from 'react-icons/go';
import { BiTime } from 'react-icons/bi';
import { AiTwotoneCalendar } from 'react-icons/ai';
import {Helmet} from 'react-helmet'


import Card from 'react-bootstrap/Card'
function Jobs() {
    const [jobs, setJobs] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(
        () => {
            axios.get('/wp-json/wp/v2/jobs')
                .then(
                    resp => {
                        let availableJobs = resp.data.filter(job => new Date(job.acf.deadline) >= Date.now());
                        setJobs(availableJobs)
                        setLoading(true)

                    }
                )
                .catch(err => console.log(err))
        }, []
    )
    return (
        <main className='jobsMain'>
            <Helmet>
                <meta charset='utf-8' />
                <title>JOBS - ZSVP ORG</title>
            </Helmet>
            {
                isLoading ?
                    <Fragment>
                        {
                            jobs.length !== 0 ?
                                <section>
                                    <h5>Available Jobs at ZSVP</h5>
                                    {
                                        jobs.map(job => {
                                            return <JobCard key={job.id} job={job} />
                                        })
                                    }
                                </section>
                                :
                                <div className='no-jobs'>No Jobs Available Now, Please come back latter! 😧  </div>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        <div>
                            Loading...
                        </div>
                    </Fragment>}
        </main>
    )
}

function JobCard(props) {
    let { title, content } = props.job;
    let { deadline, location, posted, type, contract } = props.job.acf;
    const [height, setHeight] = useState('10.5rem');
    const [class_name1, setClass_name1] = useState('active');
    const [class_name2, setClass_name2] = useState('');

    function changeHeight() {
        if (height === '10.5rem') {
            setHeight('auto');
            setClass_name1('');
            setClass_name2('active')
        } else {
            setHeight('10.5rem');
            setClass_name1('active');
            setClass_name2('')
        }
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>{title.rendered}</Card.Title>
                <Card.Subtitle className="mb-2">
                    <GoLocation />
                    {location}
                </Card.Subtitle>
                <Card.Subtitle className="mb-2">
                    <BiTime />
                    {type}
                </Card.Subtitle>
                <Card.Subtitle className="mb-2">
                    <AiTwotoneCalendar />
                    {contract}
                </Card.Subtitle>
            </Card.Header>

            <Card.Body style={{ "height": height }}>
                <Card.Text dangerouslySetInnerHTML={{ __html: content.rendered }}></Card.Text>
            </Card.Body>

            <div className='expand' style={{ "margin": "1rem", "color": "blue" }}
                onClick={() => {
                    changeHeight();
                }}>
                <span className={class_name1}>Read More...</span>
                <span className={class_name2}>Less</span>
            </div>
            <Card.Footer>
                <span>Posted: {posted}</span>
                <span>Deadline: {deadline}</span>
            </Card.Footer>
        </Card>

    )
}

export default Jobs;