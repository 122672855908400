
import Carousel from 'react-bootstrap/Carousel'
import './slider.css'

function Slider() {
    return (
        <div className="slider">
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        // src="/images/sliderImages/wideImages/slide2.webp"
                        src="/images/sliderImages/cover1s.webp"
                        srcSet='/images/sliderImages/cover1s.webp 768w, /images/sliderImages/cover1.webp 992w'
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        {/* <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        // src="/images/sliderImages/wideImages/slide2.webp"
                        src="/images/sliderImages/cover2s.webp"
                        srcSet='/images/sliderImages/cover2s.webp 768w, /images/sliderImages/cover2.webp 992w'
                        alt="Second slide"
                    />

                    <Carousel.Caption>
                        {/* <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        // src="/images/sliderImages/wideImages/slide2.webp"
                        src="/images/sliderImages/cover3s.webp"
                        srcSet='/images/sliderImages/cover3s.webp 768w, /images/sliderImages/cover3.webp 992w'
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        {/* <h3>Third slide label</h3>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Slider;