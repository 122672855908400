import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// bootstrap stylesheet
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './Header'


// components
import Footer from './components/Footer/Footer'
import Home from './components/Home'
import About from './components/About'
import TenderPage from './components/Home/TenderPage'
import Jobs from './components/Jobs'
import News from './components/News'
import NewsPage from './components/News/NewsPage'

// page not found
import PageNotFound from './PageNotFound'

// App component
function App() {
  let [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
  }, [])

  if (isLoading) {
    return (
      <div className="App">
        <Router>
          <Header />
          <Switch>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/news">
              <News />
            </Route>
            <Route exact path="/news/:id">
              <NewsPage />
            </Route>
            <Route exact path="/jobs">
              <Jobs />
            </Route>
            <Route exact path="/tenders/:id">
              <TenderPage />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route component={PageNotFound} />
          </Switch>

          <Footer />
        </Router>
      </div>
    );
  }
  return (
    <div className='loading'>
      Loading...
    </div>
  )
}


export default App;
