import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import './TenderPage.css'

function TenderPage() {
    let { id } = useParams();
    let [tender, setTender] = useState([]);
    let [isLoading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(`/wp-json/wp/v2/tenders/${id}`)
            .then(
                resp => {
                    setTender(resp.data)
                    setLoading(true)
                }
            )
            .catch(err => console.log(err));
    }, [id]);

    return (
        <main className='tender-page-main'>
            <section className='tender-page-section'>
                <Link to='/'> ⬅  Back</Link>
                {
                    isLoading ?
                        <div className='tender-details'>
                            <aside>
                                <TenderImage media={tender.featured_media} />
                            </aside>
                            <div className='tender-desc'>
                                <h5>{tender.title.rendered}</h5>
                                <p className='tender-date'>{tender.date}</p>
                                <p className='tender-desc' dangerouslySetInnerHTML={{ __html: tender.content.rendered }}></p>
                            </div>
                        </div>
                        :
                        <Fragment>Loading...</Fragment>
                }
            </section>
        </main>
    )
}


function TenderImage(props) {
    let imageId = props.media;
    const [isLoading, setLoading] = useState(false);
    const [imgUrl, setImgUrl] = useState('');
    useEffect(
        () => {
            axios.get(`/wp-json/wp/v2/media/${imageId}`)
                .then(resp => {
                    setImgUrl(resp.data.media_details.sizes.full.source_url);
                    setLoading(true)
                })
        }, [imageId]
    )
    return (
        <Fragment>
            {
                isLoading ? <img src={imgUrl} alt='TenderImage' /> : ''
            }
        </Fragment>

    )
}

export default TenderPage;