import React, { Fragment } from 'react'

import './Tenders.css'

import axios from 'axios'

// import TenderItem from './TenderItem'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'

// Tender component
class Tenders extends React.Component {
    constructor() {
        super()
        this.state = {
            tenders: [],
            isLoading: false
        }
    }
    componentDidMount() {
        axios.get('/wp-json/wp/v2/tenders')
            .then(
                resp => {
                    this.setState(
                        {
                            tenders: resp.data,
                            isLoading: true
                        }
                    )
                }
            )
            .catch(err => console.log(err));
    }
    
    render() {
        let { tenders, isLoading } = this.state;
        return (
            <section className='tendersSection' id='tenders'>
                <h3>Tenders / Bids</h3>
                { isLoading ?
                    <div className='tenderCards'>
                        {
                            tenders.length !== 0 ?
                                <div className='cardContainer'>
                                    {
                                        tenders.map(tender => {
                                            return <TenderItem key={tender.id} tender={tender} />
                                        })
                                    }
                                </div>
                                :
                                <div>No Tenders Available</div>
                        }
                    </div>
                    :
                    <Fragment>
                        Loading...
                    </Fragment>
                }
            </section>
        )
    }
}

// tender Item
class TenderItem extends React.Component {
    constructor() {
        super()
        this.state = {
            imgUrl: '',
            isLoading: false
        }
    }
    componentDidMount() {
        let { featured_media } = this.props.tender;
        axios.get(`/wp-json/wp/v2/media/${featured_media}`)
            .then(resp => {
                this.setState(
                    {
                        imgUrl: resp.data.media_details.sizes.full.source_url,
                        isLoading: true
                    }
                )
            })
            .catch(err => console.log(err));
    }
    render() {
        let { imgUrl, isLoading } = this.state;
        let tender = this.props.tender;
        if (isLoading) {
            return (
                <Fragment>
                    {
                        <Card>
                            <Card.Img variant="top" src={imgUrl} alt='TenderImage' />
                            <Card.Body>
                                <Card.Title>{tender.title.rendered}</Card.Title>
                                <Card.Text dangerouslySetInnerHTML={{ __html: tender.excerpt.rendered }}>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Link to={`/tenders/${this.props.tender.id}`}>Read more</Link>
                            </Card.Footer>
                        </Card>
                    }
                </Fragment>
            )
        }

        return null;
    }
}

export default Tenders;