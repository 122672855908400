import React from 'react'
import { Link } from 'react-router-dom'
import './IntroZSVP.css'
import { IoIosArrowForward } from 'react-icons/io'


function IntroZSVP() {
    return (
        <div className='IntroZSVP'>
            <div>
                <p>
                    ZSVP (Zakho Small Villages Projects) as a local NGO, Non-profit established in 1991, is interested on community
                    development to promote the ability of vulnerable groups of rural communities and help them for fair sustainable life.
                </p>
            </div>
            <div>
                <Link to='/about'>Learn More <IoIosArrowForward /> </Link>
            </div>
        </div>
    )
}

export default IntroZSVP;