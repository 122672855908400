import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import './Partners.css'

function Partners() {
    const [partners, setPartners] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(
        () => {
            axios.get('/wp-json/wp/v2/partners')
                .then(
                    resp => {
                        setPartners(resp.data)
                        setLoading(true)
                    }
                )
                .catch(err => console.log(err))
        }, []
    )
    return (
        <div className='partners'>
            <h3>Partners</h3>
            {
                isLoading ?
                    <div className='section-body'>
                        {
                            partners.length !== 0 ?
                                <Fragment>
                                    <div className='partner-logo-card'>
                                        {
                                            partners.map(partner => {
                                                return <Partner key={partner.id} partner={partner} />
                                            })
                                        }
                                    </div>
                                    <div className='description'>
                                        <p>
                                            <strong>ZSVP Membership: </strong>ZSVP has a membership with <strong>IFOAM</strong> (International Federation of Organic Agriculture Movement, Germany, since 1997),
                                            <strong>IIRD</strong> (International Institute of Rural Development – South Asia, 1998), <strong>Avalon Network</strong> for Sustainable Rural
                                            Development-Holland, 2007, <strong>ANND</strong> (Arabic NGOs network for environment), <strong>DNN</strong> Network and Civil Society Forum and 
                                            <strong> EIN</strong> (Iraqi Election Information Network).
                                        </p>
                                    </div>
                                </Fragment>
                                :
                                <div>No Partners Have been shown yet!</div>
                        }
                    </div>
                    :
                    <Fragment>
                        <div>
                            Loading...
                        </div>
                    </Fragment>}
        </div>
    )
}

// partner
function Partner(props) {
    let { title, featured_media } = props.partner;
    const [imgUrl, setImgUrl] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(
        () => {
            axios.get(`/wp-json/wp/v2/media/${featured_media}`)
                .then(resp => {
                    setImgUrl(resp.data.media_details.sizes.full.source_url);
                    setLoading(true)
                })
                .catch(err => console.log(err))
        }, [featured_media]
    )
    return (
        <Fragment>
            {
                isLoading ?
                    <figure>
                        <img src={imgUrl} alt={title.rendered} />
                        <figcaption> {title.rendered} </figcaption>
                    </figure>
                    :
                    <Fragment></Fragment>
            }

        </Fragment>
    )
}

export default Partners;