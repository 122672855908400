import React from 'react'
import './About.css'
import ListGroup from 'react-bootstrap/ListGroup'
import { Helmet } from 'react-helmet'
function About() {
    return (
        <main className='aboutMain'>
            <Helmet>
                <meta charset='utf-8' />
                <title>ABOUT - ZSVP ORG</title>
            </Helmet>
            <section className='aboutSection'>
                <AboutZSVP />
            </section>
        </main>
    )
}

class AboutZSVP extends React.Component {

    constructor() {
        super()
        this.state = {
            listId: 0
        }
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event) {
        let id = parseInt(event.target.id);
        this.setState({ listId: id })
    }
    render() {
        return (
            <div className='aboutZsvp'>
                <aside className='sidebar'>
                    <ListGroup variant="flush">
                        <ListGroup.Item onClick={this.handleClick} id='0'>
                            Who We Are?
                        </ListGroup.Item>
                        <ListGroup.Item onClick={this.handleClick} id='1'>
                            Mission and Objectives
                        </ListGroup.Item>
                        <ListGroup.Item onClick={this.handleClick} id='2'>
                            Sector of focuse
                        </ListGroup.Item>
                        <ListGroup.Item onClick={this.handleClick} id='3'>
                            Core Values of ZSVP
                        </ListGroup.Item>
                        <ListGroup.Item onClick={this.handleClick} id='4'>
                            People of Conceren
                        </ListGroup.Item>
                    </ListGroup>
                </aside>
                <section className='secondSection'>
                    <Topic list={this.state.list} id={this.state.listId} />
                </section>
            </div>
        )
    }
}

function Topic(props) {
    let index = props.id;
    let options = {
        0: <WhoWeAre />,
        1: <Mission />,
        2: <Focuse />,
        3: <Values />,
        4: <People />
    }

    return options[index];
}


// Who we are?
function WhoWeAre() {
    return (
        <div className='whoweare'>
            <h5>ZSVP ORG: Who we are?</h5>
            <p style={{ textAlign: 'justify' }}>
                ZSVP (Zakho Small Villages Projects) as a local NGO, Non-profit established in 1991, is interested on community
                development to promote the ability of vulnerable groups of rural communities and help them for fair sustainable life.
                <br />
                In 1991, after the First Gulf War, ZSVP took part in the relief efforts for the refugees and internally displaced
                people and in reconstructing of infrastructure of rural areas of Dohuk governorate through rebuilding (shelters,
                water projects, schools, clinics, irrigation channels, and roads) to enable people to return back to their v
                illages and start their life. After the UN resolution 1996, ZSVP moved away from relief oriented approach to a more developmental approach.
                Since 1998, ZSVP has disseminated environmental information and conducted workshops and training courses for
                diffusing sustainable agriculture methods and environmentally sound technologies, integrated pest management
                techniques, nutrition methods water and environmental health care to various groups’ (farmers, rural women,
                extension agents, students and NGOs).
                <br />
                Since 2003, ZSVP has changed its interests and activities to respond to new conditions in Iraq because of the last
                Gulf War – ZSVP Contributed in managing refugees camps in emergency program prepared by international agencies and
                local administration to provide required needs to displaced people and fled families from fighting area post the war.
                In April 2003, ZSVP was the first organization to be established in the liberated areas of Mosul and focus on IDPs
                issues to assist them to improve their living conditions and reporting their problems and needs to the relevant parties.
                <br />
                Since April 2003, ZSVP has implemented hundreds of  projects and activities in the new liberated  area of Mosul, Erbil and
                Kirkuk, by providing clinic centers with medical equipment, distributing food and other materials to IDPs and returnees,
                building capacity activities for IDPs and health awareness program, cash for work and income generation project for refugees.
                <br /> <br />
                Due to the last crises raised in 2014 after control of ISIS to more than 30% of Iraq which was the reason behind of displacement
                of more than 4 million peoples from different governorates of middle and south of Iraq to KRI, ZSVP to Follow up the changes and
                the crises conflicts have seen a big role through support of IDPs and refugees with Food aid assistance and NFI to save peoples
                and contribute toward the development and reintegration of crises affected groups (IDPS,  returnees and Host community with women
                and youth) into their communities through supporting small agro-income generating activities and women empowerments in  Duhok and
                Ninawa  province area through:<br />
                Improved long term income generation, job creation and other related livelihood initiatives for IDPs, Returnees and host communities,
                especially women, youth and other vulnerable groups ,through supporting the following scheme of small income generating projects that
                are including but not limited to:
                <ul style={{ paddingLeft: '2rem' }}>
                    <li>Micro- and SME businesses strengthening for the most vulnerable groups who are affected by the crises.</li>
                    <li>
                        Vocational and technical, financial and administrative skills enhance of families to respond to market needs,
                        as well as facilitates self-employment and on-the job training;
                    </li>
                    <li>
                        Increase access to productive resources  among  IDPs, Returnees and Host community including
                        women and youth in livelihood sectors
                    </li>
                </ul>
                <br />
                <h5 style={{ textAlign: 'left' }}>
                    Monitoring and evaluation
                </h5>
                <p>
                    ZSVP within his continues work found that the assessments (skills needed assessment, area needs assessment for projects)
                    are highly recommended to be carried out before implementing any activities that would ensure the better achievement for
                    the targeted people and for the sustainability of the implemented activities. Evaluation of the impact of the implemented
                    projected also found as one of the crucial activity to be taken to maintain the success and sustainability of the projects
                    needed.
                </p>
            </p>
        </div>
    )
}
// ZSVP Mission
function Mission() {
    return (
        <div className='mission'>
            <h5>Mission and Objectives</h5>
            <p>
                <h5>Mission: </h5>
                interested on community development to promote the ability of vulnerable groups of rural communities and help them
                for fair sustainable life.
                <br />
                <br />
                <h5>ZSVP strategic objectives</h5>
                <ol style={{ paddingLeft: '2rem' }}>
                    <li>To contribute in enhancing the food security of rural poor people and conserve their resource base.</li>
                    <li>To promote income generating activities and create vocational skills for the youngsters, women, and IDPs to become self-sufficient and reduce the level of poverty living.</li>
                    <li>Participation in the development of supply of potable water and proper sanitation through supporting and implementation of new projects and maintenance of existing projects, especially in rural areas, and organizing awareness courses on water handling and conservation.</li>
                    <li>To promote gender equality and end violence against women, children and youth in the IDPs locations. In particular, seeks to promote the health and empowerment of young women through critical reflections about gender, rights and health.</li>
                    <li>To activate the role of rural women in the economic and social life of their communities.</li>
                    <li>To help and support individuals, groups and IDPs and returnees and reintegrate them within  the community especially those in under-served and poor areas.</li>
                    <li>To contribute in enhancing sustainable use and management of land and water resources.</li>
                    <li>To increase the status of agriculture and rural societies to be a priority in development process in the region.</li>
                    <li>To use, as far as possible, renewable resources in farming or elsewhere.</li>
                </ol>
            </p>
        </div>
    )
}

// ZSVP Sector of Focuse
function Focuse() {
    return (
        <div className='focuse'>
            <h5>Sector of focuses on</h5>
            <ol style={{ paddingLeft: '2rem' }}>
                <li>Agricultural infrastructure and livestock</li>
                <li>Returnees, IDPs Reintegration and psychosocial support</li>
                <li>Livelihood support</li>
                <li>Gender equality and end violence against women, children and youth</li>
                <li>Water and Sanitation</li>
                <li>Emergency Operation/food assistance</li>
                <li>Vocational skills trainings and create employment opportunities</li>
                <li>Humanitarian Emergency Assistance</li>
                <li>Assessments./Environmental, need and skill, marketing</li>

            </ol>
        </div>
    )
}

// Core Values of ZSVP
function Values() {
    return (
        <div className='values'>
            <h5>ZSVP Core Values</h5>
            <ol style={{ paddingLeft: '2rem' }}>
                <li>Accountably and Transparency</li>
                <li>Human rights</li>
                <li>Responsibility</li>
                <li>Confidentiality</li>
                <li>Respect for others</li>
                <li>Commitment and trust</li>
                <li>Networking</li>
                <li>Performance and team working</li>
                <li>Peace culture and pluralism</li>
                <li>Serving people in equal approach in line with our Organizational slogans.</li>
                <li>Mutual respect and partnership with the development humanitarian actors’ include human rights and media groups (Agencies).</li>
            </ol>
        </div>
    )
}

// People of Conceren
function People() {
    return (
        <div className='people'>
            <h3>People of Conceren</h3>
            <p>
                ZSVP support peoples anywhere to have the rights as a human (IDPs, Refugees and Host communities)
            </p>
        </div>
    )
}

export default About;