import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import './News.css'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import { Helmet } from 'react-helmet'

function News() {
    const [news, setNews] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(
        () => {
            axios.get('/wp-json/wp/v2/news')
                .then(
                    resp => {
                        setNews(resp.data)
                        setLoading(true)
                    }
                )
                .catch(err => console.log(err))
        }, []
    )
    return (
        <main className='newsMain'>
            <Helmet>
                <meta charset='utf-8' />
                <title>NEWS - ZSVP ORG</title>
            </Helmet>
            {
                isLoading ?
                    <Fragment>
                        {
                            news.length !== 0 ?
                                <section className='newsSection'>
                                    {
                                        news.map(newsItem => {
                                            return <NewsDetails key={newsItem.id} newsItem={newsItem} />
                                        })
                                    }
                                </section>
                                :
                                <div>
                                    No News Yet!
                                </div>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        <div>Loading...</div>
                    </Fragment>}
        </main>
    )
}


function NewsDetails(props) {

    let { title, excerpt, date, featured_media, id } = props.newsItem;
    const [imgUrl, setImgUrl] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(
        () => {
            axios.get(`/wp-json/wp/v2/media/${featured_media}`)
                .then(resp => {
                    setImgUrl(resp.data.media_details.sizes.full.source_url);
                    setLoading(true)
                })
                .catch(err => console.log(err))
        }, [featured_media]
    )
    return (
        <Fragment>
            {
                isLoading ?
                    <div className='news'>
                        <Card>
                            <Card.Title dangerouslySetInnerHTML={{ __html: title.rendered }}></Card.Title>

                            <Card.Img variant="top" src={imgUrl} alt={title.rendered} />

                            <Card.Body>
                                <Card.Subtitle>{date}</Card.Subtitle>
                                <Card.Text dangerouslySetInnerHTML={{ __html: excerpt.rendered }}></Card.Text>
                                <Link to={`/news/${id}`}>Continue reading...</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    :
                    <Fragment></Fragment>
            }

        </Fragment>
    )
}

export default News;