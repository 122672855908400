import React from 'react'
import { Link, useLocation} from "react-router-dom";
// from bootstap
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import './app.css'

function Header() {
  let location = useLocation();
  
  return (
    <header>
            <Navbar expand="md">
              <Navbar.Brand>
                <Link to='/'>
                  <img src='/images/ZSVPlogo.PNG' alt='ZSVP ORG' style={{ width: '64px', height: '64px' }} />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  <li className={ location.pathname === '/' ? 'topNavigation-activeItem': null}><Link to='/' name='HomePage'>Home</Link></li>
                  <li className={ location.pathname === '/about' ? "topNavigation-activeItem": null}><Link to='/about' name='AboutPage'>About</Link></li>
                  <li className={ location.pathname === '/news' ? "topNavigation-activeItem": null}><Link to='/news' name='NewsPage'>News</Link></li>
                  <li className={ location.pathname === '/#tenders' ? "topNavigation-activeItem": null}><Link to='/#tenders' name='TenderSection'>Tenders</Link></li>
                  <li className={ location.pathname === '/jobs' ? "topNavigation-activeItem": null}><Link to='/jobs' name='JobPage'>Jobs</Link></li>
                  <li>
                    <a href='https://gator3288.hostgator.com:2096/webmaillogout.cgi'
                      target='_blank' rel='noopener noreferrer'>
                      Log in</a>
                  </li>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </header>
  );
}

export default Header;